.login-box{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30%;
    height: auto;
    min-height: 45%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-color:  #ffffff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding: 1% 2% 1% 2%;
    border-radius: 1%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.login-box-responsive{
    margin-top: 10%;
    margin-bottom: 10%;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80% !important;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-color:  #ffffff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding: 1% 2% 1% 2%;
    border-radius: 1%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.btn-container{
    display: flex;
    justify-content: center;
    margin-top: 4vh;
    margin-bottom: 2vh;
    min-height: 50px
}

.my-input {
    margin-top: 1%;
    margin-bottom: 1%;
}

.login-form-forgot {
    margin: 0 0 0 0;
    display: flex;
    justify-content: flex-end;
    min-height: 10px;
}

.title {
    margin-top: 10%;
    color:#ffffff;
    display: flex;
    justify-content: center;
    font-size: 350%;
    font-weight: bold;
    text-shadow: 2px 2px rgba(0, 0, 0, 0.2);
}

.image-container {
    display: flex;
    justify-content: center;
    margin-top: 5%;
    margin-bottom: 2%;
}

.image {
    width: 100%;
    height: auto;
    margin-top: 2%;
}

.ant-form-item-control-input-content {
    flex: auto;
    max-width: 100%;
    min-height: 40px !important;
}