html *
{
   font-family: Verdana, Geneva, Tahoma, sans-serif !important;
   text-decoration: wavy;
}

.tableTitle {
    margin-top: 3.6vh;
    margin-bottom: 5vh !important;
    display: flex;
    justify-content: center;
    color: #1890ff !important;
    font-size: large;
}

@media only screen and (max-width: 575px) {
    .titleContainerResponsive {
        width: 90% !important;
        display: flex;
        justify-content: space-between;
    }
}

.titleContainer {
    width: 58%;
    display: flex;
    justify-content: space-between;
}

.titleBack {
    display: flex;
    justify-content: center;
    color: #1890ff !important;
    font-size: large;
}

.back-button {
    margin-top: 4vh;
}

.subscriptionInfo {
    display: flex;
    justify-content: space-evenly;
}

.recharts-wrapper {
    width: 100%;
}

.content {
    padding: 2% 10% 2% 10%;
    width: 100%;
    background-color: white;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

td.ant-table-column-sort {
    background: inherit !important;
}

.cell {
    display: flex;
    justify-content: center;
}

.overlay {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0, 0.9);
    overflow-x: hidden;
    transition: 0.5s;
}

html {
    overflow: scroll;
}

::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background: transparent; /* make scrollbar transparent */
}

.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover {
    background-color: #5572cc9c !important;
}