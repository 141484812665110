.header {
    justify-content: space-between;
    min-height: 60px !important;
    max-height: 60px !important;
}

.headerTitle {
    color:#ffffff;
    font-weight: bold;
    text-shadow: 2px 2px rgba(54, 53, 53, 0.2);
}

.opt {
    padding-left: 10%;
    padding-right: 10%;
}

.opt:hover {
    cursor: pointer;
    background-color: #e8e8e8;
}