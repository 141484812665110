.dark-row{
    background-color: #fafafa;
}

.light-row {

}

.verticalBorder th,
td {
  border: 1px solid rgba(224, 224, 224, 1);
}

.MUIDataTableHeadCell-fixedHeader-32 {
    z-index: 1 !important;
}

.MUIDataTableHeadCell-fixedHeader-200 {
  top: 0px;
  z-index: 0 !important;
  position: inherit !important;
  background-color: #fff;
}

.MUIDataTableHeadCell-fixedHeader-157 {
  top: 0px;
  z-index: 100;
  position: inherit !important;
  background-color: #fff;
}

.MUIDataTableHeadCell-fixedHeader-47 {
  top: 0px;
  z-index: 0 !important;
  position: inherit !important;
  background-color: #fff;
}

.MUIDataTableHeadCell-fixedHeader-73 {
  top: 0px;
  z-index: 100;
  position: inherit !important;
  background-color: #fff;
}

.MUIDataTableHeadCell-fixedHeader-215 {
  top: 0px;
  z-index: 100;
  position: inherit !important;
  background-color: #fff;
}

.MUIDataTableHeadCell-fixedHeader-131 {
  top: 0px;
  z-index: 100;
  position: inherit !important;
  background-color: #fff;
}

.MUIDataTableHeadCell-fixedHeader-299 {
  top: 0px;
  z-index: -1;
  position: sticky;
  background-color: #fff;
}

.MUIDataTableHeadCell-fixedHeader-383 {
  top: 0px;
  z-index: 0;
  position: sticky;
  background-color: #fff;
}

.MUIDataTableHeadCell-fixedHeader-551 {
  top: 0px;
  z-index: 0;
  position: sticky;
  background-color: #fff;
}

.MUIDataTableHeadCell-fixedHeader-467 {
  top: 0px;
  z-index: 0;
  position: sticky;
  background-color: #fff;
}

.MUIDataTableHeadCell-fixedHeader-551 {
  top: 0px;
  z-index: 0;
  position: sticky;
  background-color: #fff;
}

.MUIDataTableHeadCell-fixedHeader-635 {
  top: 0px;
  z-index: 0;
  position: sticky;
  background-color: #fff;
}

.MUIDataTableHeadCell-fixedHeader-383 {
  top: 0px;
  z-index: 0;
  position: sticky;
  background-color: #fff;
}

