.tag-container {
    text-transform: capitalize;
    display: flex;
    justify-content: space-between;
}

.custom-tag {
    text-align: center;
    width: 90px;
    max-height: 22px;
    overflow: hidden;
}

.variable-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: -13px;
}